<template>
  <div class="feedback">
    <div class="table"
         v-if="!showInfo">
      <div class="head">
        我的反馈
      </div>
      <a-table :columns="columns"
               style="width: 100%"
               :data-source="tableData">
        <template #bodyCell="{ column, record }">

          <div v-if="column.key == 'question_id'">
            <span class="text">
              <!-- {{rands(record.question_id)}} -->
              {{record.question_id}}

            </span>
          </div>
          <div v-if="column.key == 'status'">
            <span class="text"
                  :style="{'color':getColor(record.status)}">
              {{getValue(record.status)}}
            </span>
          </div>
          <div v-else-if="column.key == 'opertion'">
            <div class="btn"
                 @click="getInfo(record)">
              查看详情
            </div>
          </div>
        </template>
      </a-table>
    </div>

    <div v-else>
      <div class="head">
        题目详情
      </div>

      <div class="warp">
        <div class="topic_head">题目内容</div>
        <div v-html="info.question_content"
             class="btm_bdr">
        </div>
      </div>
      <div class="warp">
        <div class="topic_head">
          题目答案
        </div>
        <div v-html="info.question_answer"
             class="btm_bdr">
        </div>
      </div>

      <div class="warp">
        <div class="topic_head">
          题目解析
        </div>
        <div v-html="info.question_explain"
             style="font-size:22rem"></div>

      </div>
      <a-row :gutter="10"
             style="margin-top:10px"
             v-if="info.category == 1">
        <span class="comment">
          分数：{{info.user_score}}
        </span>
      </a-row>

      <a-row :gutter="10"
             style="margin-top:10px"
             v-if="info.category == 1">
        <span class="comment">
          评语：{{info.comment_to_user}}
        </span>
      </a-row>
      <a-row :gutter="10">
        <a-col :span="24">
          <div :class="`status_btn ${getStatusClass(info.status)}`">
            {{getValue(info.status)}}
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="10"
             v-if="info.teacher_comment">
        <span class="comment">
          未通过原因：{{info.teacher_comment}}
        </span>
      </a-row>
      <div class="img_wrap">
        <a-image v-for="(item,index) in info.counter_image"
                 :key="index"
                 style="width: 100px; height: 100px;margin-right:10px;border-radius:10px"
                 :src="item"
                 :preview-src-list="info.counter_image">
        </a-image>
      </div>

      <div style="width:100px;height:30px"></div>
    </div>

  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity"
import { getFeedbackList, getFeedbackInfo } from '@/api/user.js'
import { onMounted } from "@vue/runtime-core"
import { rands } from '@/utils/rand.js'
let columns = [
  {
    title: '题目ID',
    dataIndex: 'question_id',
    key: 'question_id',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '',
    dataIndex: 'opertion',
    key: 'opertion',
  },
]
let tableData = ref([])
let showInfo = ref(false)
let info = reactive({})

onMounted(() => {
  getList()
})

const getValue = (status) => {
  console.log('status', status)
  var str = ''
  switch (status) {
    case -1:
      str = '未通过'
      break;
    case 0:
      str = '待审核'
      break;
    case 1:
      str = '审核通过'
      break;
    case 2:
      str = '修改完成'
      break;
  }
  return str;
}

const getColor = (status) => {
  var color = ''
  switch (status) {
    case -1:
      color = '#FF664E'
      break;
    case 0:
      color = '#1584F0'
      break;
    case 1:
      color = '#2ACE84'
      break;
    case 2:
      color = '#2ACE84'
      break;
  }
  return color;
}

const getStatusClass = (status) => {
  var classname = '';
  switch (status) {
    case -1:
      classname = 'wtg'
      break;
    case 0:
      classname = 'wsh'
      break;
    case 1:
      classname = 'tg'
      break;
    case 2:
      classname = 'tg'
      break;
  }
  return classname
}

const getList = async () => {
  const { data } = await getFeedbackList()
  tableData.value = data.lst
}
const getInfo = async (row) => {
  let params = {
    question_error_id: row.question_error_id
  }
  const { data } = await getFeedbackInfo(params)
  showInfo.value = true
  for (const key in data) {
    info[key] = data[key]
  }
}
</script>

<style lang="scss" scoped>
.feedback {
  width: calc(100% - 140rem);
  margin: 0 auto;
  padding-top: 40rem;
  height: 100%;
  overflow: auto;
  .head {
    font-size: 28rem;
    font-weight: 800;
    color: #000000;
    display: flex;
    justify-content: center;
    margin-bottom: 28rem;
  }
}
.btn {
  background: #d8e6fc;
  color: #1584f0;
  font-size: 16rem;
  font-weight: bold;
  padding: 5px 0;
  border-radius: 20px;
  width: 110px;
  margin: 0 auto;
  float: right;
  margin-right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-table tr {
  box-shadow: 0px 0px 0px 0px rgba(229, 229, 229, 1);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}

::v-deep .el-table th,
.el-table tr {
  background: #f6f6f6 !important;
}

.text {
  font-size: 24rem;
  font-weight: bold;
  color: #000000;
}
.warp {
  .topic_head {
    font-size: 22rem;
    font-weight: bold;
    color: #000000;
    border-left: 6rem solid #37a0f4;
    text-indent: 14rem;
    height: 22rem;
    line-height: 22rem;
    margin-bottom: 10px;
  }
}
.btm_bdr {
  border-bottom: 2rem solid #cccccc;
  margin-bottom: 18rem;
  font-size: 22rem;
}
.status_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150rem;
  height: 48rem;
  border-radius: 35rem;
  font-size: 22rem;
  font-weight: bold;
  margin-top: 25rem;
}
.wsh {
  background: #d8e6fc;
  color: #007af0;
}
.wtg {
  background: #fedddd;
  color: #ff664e;
}
.tg {
  background: #e4f9ed;
  color: #2ace84;
}
.comment {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.img_wrap {
  display: flex;
  flex-wrap: wrap;
}
:deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  font-size: 24rem;
}
</style>